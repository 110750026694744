.home .card {
  min-height: 331px;
}

.popular {
  img {
    max-width: 300px;
  }
  a {
    color: #f7862f;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 25px;
  }
}
